import React, { useEffect, useState, useMemo } from 'react';
import { Table, Pagination, Input, Modal, Button, Collapse, Space, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import useFetchData from '../hooks/useFetchData';
import axios from 'axios';

const { Panel } = Collapse;

interface DomainItem {
  domain: string;
  keyword: string;
  status: string;
  wp_login: string;
  wp_pass: string;
  owner_id: number;
  added_at: string;
  [key: string]: any; // Дополнительные поля для модального окна
}

const DomainData: React.FC = () => {
  const [domainName, setDomainName] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<DomainItem | null>(null);
  const navigate = useNavigate();

  const params = useMemo(() => ({ domain_name: domainName, page, size }), [domainName, page, size]);

  const { data, loading, error } = useFetchData<{ items: DomainItem[]; total: number }>(
    'https://api.wp-generate.site/domains',
    params
  );

  useEffect(() => {
    if (error) {
      message.error(error);
      navigate('/login');
    }
  }, [error, navigate]);

  const openModal = (domain: DomainItem) => {
    setSelectedDomain(domain);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDomain(null);
  };

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input.Search
          placeholder="Поиск по домену"
          onSearch={(value) => setDomainName(value)}
          enterButton
        />
        <Table
          columns={[
            { title: 'Домен', dataIndex: 'domain', key: 'domain' },
            { title: 'Ключевое слово', dataIndex: 'keyword', key: 'keyword' },
            { title: 'Статус', dataIndex: 'status', key: 'status' },
            { title: 'WP Login', dataIndex: 'wp_login', key: 'wp_login' },
            { title: 'WP Pass', dataIndex: 'wp_pass', key: 'wp_pass' },
            { title: 'Владелец', dataIndex: 'owner_id', key: 'owner_id' },
            { title: 'Дата добавления', dataIndex: 'added_at', key: 'added_at' },
            {
              title: 'Действия',
              key: 'actions',
              render: (_, record) => (
                <Button onClick={() => openModal(record)}>Подробнее</Button>
              ),
            },
          ]}
          dataSource={data?.items || []}
          loading={loading}
          pagination={false}
          rowKey="domain"
        />
        <Pagination
          current={page}
          total={data?.total || 0}
          pageSize={size}
          onChange={(newPage) => setPage(newPage)}
          showSizeChanger={false}
        />
      </Space>

      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        footer={<Button onClick={closeModal}>Закрыть</Button>}
        width={800}
      >
        {selectedDomain && (
          <div>
            <Table
              dataSource={Object.entries(selectedDomain).map(([key, value]) => ({
                key,
                field: key,
                value: value,
              }))}
              columns={[
                { title: 'Ключ', dataIndex: 'field', key: 'field' },
                { title: 'Значение', dataIndex: 'value', key: 'value' },
              ]}
              pagination={false}
              showHeader={false}
            />
            <Collapse>
              <Panel header="Дополнительные данные" key="1">
                <pre>{JSON.stringify(selectedDomain, null, 2)}</pre>
              </Panel>
            </Collapse>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DomainData;
