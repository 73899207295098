import { useState, useEffect, useRef, useCallback } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

interface FetchState<T> {
  data: T | null;
  loading: boolean;
  error: string | null;
}

const useFetchData = <T,>(url: string, params: object = {}, config: AxiosRequestConfig = {}): FetchState<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const prevParamsRef = useRef<string | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get<T>(url, { params, ...config, withCredentials: true });
      setData(response.data);
    } catch (e) {
      setError('An error occurred while fetching data.');
    } finally {
      setLoading(false);
    }
  }, [url, params, config]);

  useEffect(() => {
    const paramsString = JSON.stringify(params);

    // Проверяем, изменились ли параметры, или это первый запуск
    if (prevParamsRef.current !== paramsString || prevParamsRef.current === null) {
      fetchData(); // Запрашиваем данные
      prevParamsRef.current = paramsString; // Обновляем предыдущее значение
    }
  }, [fetchData, params]);

  return { data, loading, error };
};

export default useFetchData;
