import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ServerData from './components/ServerData';
import Login from './components/Login';
import { Layout } from 'antd';
import 'antd/dist/reset.css';
import DomainData from "./components/DomainData";

const { Header, Content, Footer } = Layout;

const App: React.FC = () => {
  return (
    <Router>
      <Layout className="layout">
        <Header>
          <div className="logo" />
        </Header>
        <Content style={{ padding: '0 50px' }}>
          <div className="site-layout-content" style={{ minHeight: '80vh', marginTop: '16px' }}>
            <Routes>
              <Route path="/" element={<DomainData />} />
              <Route path="/servers" element={<ServerData />} />
              <Route path="/login" element={<Login />} />
            </Routes>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Mediapoint ©2024</Footer>
      </Layout>
    </Router>
  );
};

export default App;