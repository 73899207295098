import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Pagination, message, Modal, Button, Space, Divider, Collapse } from 'antd';
import useFetchData from '../hooks/useFetchData';
import { ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { Panel } = Collapse;

interface ServerItem {
  id: number;
  ip: string;
  login: string;
  password: string;
  port: number;
  status: string;
  owner_id: number;
  added_at: string;
}

const ServerData: React.FC = () => {
  const [page, setPage] = useState<number>(1);
  const [size] = useState<number>(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serverData, setServerData] = useState<any>(null);
  const [serverStats, setServerStats] = useState<any>(null);
  const [selectedServer, setSelectedServer] = useState<ServerItem | null>(null);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null); // Новый state для хранения ID интервала
  const navigate = useNavigate();

  const params = useMemo(() => ({ page, size }), [page, size]);

  const { data, loading, error } = useFetchData<{ items: ServerItem[]; total: number }>(
    `https://api.wp-generate.site/server`,
    params
  );

  useEffect(() => {
    if (error) {
      message.error(error);
      navigate('/login');
    }
  }, [error, navigate]);

  // Запрос данных для модального окна
  const fetchServerStats = async (server: ServerItem) => {
    try {
      const [cpu, mem, uptime, disk, network] = await Promise.all([
        axios.get(`http://${server.ip}:61208/api/3/cpu`),
        axios.get(`http://${server.ip}:61208/api/3/mem`),
        axios.get(`http://${server.ip}:61208/api/3/uptime`),
        axios.get(`http://${server.ip}:61208/api/3/diskio`),
        axios.get(`http://${server.ip}:61208/api/3/network`),
      ]);

      setServerStats({
        cpu: cpu.data.total,
        mem: mem.data.percent,
        uptime: uptime.data,
        disk: disk.data,
        network: network.data,
      });
    } catch (error) {
      console.error('Error fetching server stats:', error);
      message.error('Ошибка при получении данных сервера');
    }
  };

  const openModal = (server: ServerItem) => {
    setSelectedServer(server);
    setIsModalOpen(true);
    fetchServerStats(server);

    // Устанавливаем интервал для обновления данных
    const id = setInterval(() => fetchServerStats(server), 5000);
    setIntervalId(id); // Сохраняем ID интервала
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setServerStats(null);
    setSelectedServer(null);

    // Очищаем интервал при закрытии модального окна
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null); // Сбрасываем ID интервала
    }
  };

  return (
    <div>
      <Table
        columns={[
          { title: 'ID', dataIndex: 'id', key: 'id' },
          { title: 'IP', dataIndex: 'ip', key: 'ip' },
          { title: 'Login', dataIndex: 'login', key: 'login' },
          { title: 'Password', dataIndex: 'password', key: 'password' },
          { title: 'Port', dataIndex: 'port', key: 'port' },
          { title: 'Status', dataIndex: 'status', key: 'status' },
          { title: 'Owner ID', dataIndex: 'owner_id', key: 'owner_id' },
          { title: 'Added At', dataIndex: 'added_at', key: 'added_at' },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <Button onClick={() => openModal(record)}>Управлять</Button>
            ),
          },
        ]}
        dataSource={data?.items || []}
        loading={loading}
        pagination={false}
        rowKey="id"
      />
      <br />
      <Pagination
        current={page}
        total={data?.total || 0}
        pageSize={size}
        onChange={(page) => setPage(page)}
        showSizeChanger={false}
      />

      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="reloadServer" onClick={() => console.log('Server reloaded')}>Перезагрузить сервер</Button>,
          <Button key="reloadApache" onClick={() => console.log('Apache reloaded')}>Перезагрузить Apache</Button>,
          <Button key="close" type="primary" onClick={closeModal}>Закрыть</Button>,
        ]}
        width={800} // Задаем ширину окна
      >
        {selectedServer && (
          <div>
            <Space size="large">
              <div>CPU: {serverStats?.cpu}%</div>
              <div>MEM: {serverStats?.mem}%</div>
              <div>Uptime: {serverStats?.uptime}</div>
            </Space>
            <Divider />

            <Collapse>
              <Panel header="DISK" key="1">
                {serverStats?.disk?.map((disk: any, index: number) => (
                  <div key={index}>{disk.disk_name}: {disk.write_bytes} bytes written</div>
                ))}
              </Panel>
              <Panel header="Network" key="2">
                {serverStats?.network?.map((network: any, index: number) => (
                  <div key={index}>{network.interface_name}: RX {network.rx}, TX {network.tx}</div>
                ))}
              </Panel>
            </Collapse>

            <Divider />
            <Table
              columns={[
                { title: 'Key', dataIndex: 'key', key: 'key' },
                { title: 'Value', dataIndex: 'value', key: 'value' },
              ]}
              dataSource={Object.entries(selectedServer).map(([key, value]) => ({ key, value }))}
              pagination={false}
              rowKey="key"
            />
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ServerData;
